import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Spinner from "./components/UI/Spinner";
import store from "./redux/";
import Config from "./configure";
import "./index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.scss";
import "./assets/fonts/feather/css/feather.css";
import "flag-icon-css/sass/flag-icon.scss";

const App = lazy(() => import("./App"));

const app = (
    <Provider store={store}>
        <BrowserRouter basename={Config.basename}>
            <Suspense fallback={<Spinner />}>
                <App />
            </Suspense>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
