const todoData = {
  todoCardData: [
    {
      id: 1,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 2,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 3,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 4,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 5,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 6,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    }
  ],
  todoListData: [
    {
      id: 1,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 2,
      text: "Industry's standard dummy text ever since the 1500s",
      completed: false
    },
    {
      id: 3,
      text: "The point of using Lorem Ipsum is that it has a more-or-less",
      completed: false
    },
    {
      id: 4,
      text: "Contrary to popular belief",
      completed: false
    },
    {
      id: 5,
      text: "There are many variations of passages of Lorem Ipsum",
      completed: false
    },
    {
      id: 6,
      text: "Sed ut perspiciatis unde omnis iste natus",
      completed: false
    },
    {
      id: 7,
      text: "must explain to you how all this mistaken idea",
      completed: false
    }
  ],
  todoModaData: [
    {
      id: 1,
      text: "Lorem Ipsum Dolor Sit Amet",
      completed: false
    },
    {
      id: 2,
      text: "Industry's standard dummy text ever since the 1500s",
      completed: false
    },
    {
      id: 3,
      text: "The point of using Lorem Ipsum is that it has a more-or-less",
      completed: false
    },
    {
      id: 4,
      text: "Contrary to popular belief",
      completed: false
    }
  ]
};
export default todoData;
